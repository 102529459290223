import appStore from "../images/app-store.png"
import googlePlay from "../images/google-play.png"
import background from "../images/clickclick_iphone.png"
import React from 'react';
import { useTranslation } from "react-i18next";
import Config from "../config";
import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img3 from '../images/img3.png';

export default function DownloadPage() {
    const { t } = useTranslation();
    return (
        <>
            <div className="position-relative overflow-hidden" >
                <div className="container h-100">
                    <div className="row position-relative justify-content-center mt-5">
                        <div className="offset-xl-1 col-xl-5 col-lg-6 col-md-8 col-sm-9" data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
                            <div className="banner-image-l-13">
                                <img src={background} alt="" className="w-100" style={{maxWidth: 350}} />
                            </div>
                        </div>
                        <div style={{marginTop: "10%"}} className="offset-xl-1 col-xl-5 col-lg-6 col-md-8 col-sm-9" data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
                            <div className="content">
                                <h1>{t('downloadApp')}</h1>
                                <p>{t('text4')}</p>
                                <div className="apps-btn">
                                    <a href="https://apps.apple.com/es/app/click-click-rd/id1626472011" className="app-store"><img src={appStore} alt="" /></a>
                                    <a href={Config.androidStoreUrl} className="app-store"><img src={googlePlay} alt="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-shape-13"></div>

                
            </div>

            <div className="main-box pt-5">
                <div className='bg-color-blue'>

                    <div className='info-home-container pt-5 pb-5'>
                    <h3 className='mb-3'>{t('text5')}<br></br>{t('text6')}</h3>
                    <div>
                        <p>{t('text7')}</p>
                        <p>{t('text8')}</p>
                        <p className='mb-0'>{t('text9')}</p>
                    </div>
                    </div>
                </div>

                <div className='mt-5 mb-5 news-container'>
                    <div className="row featurette pb-5" >
                        <div className="col-md-6 order-md-2 single-container">
                            <h2 className="featurette-heading ">Home, sweet home.<br/> {t('text10')}  <span className="text-body-secondary-2">{t('text11')}</span></h2>
                            <p>{t('text12')}</p>

                            <p>{t('text13')}</p>

                            <p>{t('text14')}</p>
                        </div>
                        <div className="col-md-6 order-md-1 ">
                            <img src={img1} />
                        </div>
                    </div>

 
                    <div className='bg-color-blue pt-5'>

                        <div className="row featurette pb-5" >
                            <div className="col-md-6 single-container" >
                                <h2 className="featurette-heading" style={{color:'white'}}>
                                <span className="text-body-secondary-2">{t('text15')}</span> <span className="text-body-secondary-2"></span>{t('text16')}</h2>
                                <p>{t('text17')}</p>
                                <p>{t('text18')}</p>
                                <p>{t('text19')}</p>
                            </div>
                            <div className="col-md-6">
                                <img src={img2} />
                            </div>
                        </div>
                    </div>

                    <div className="row featurette pt-5">
                        <div className="col-md-6 order-md-2 single-container">
                            <h2 className="featurette-heading">{t('text20')} <span className="text-body-secondary-2">{t('text21')}</span></h2>
                            <p>{t('text22')}</p>
                            <p>{t('text23')}</p>
                            <p>{t('text24')}</p>
                            <a style={{ fontWeight: 'bold', width: 200 }} className='btn btn-clickclick  mb-3' href="mailto:broker@clickclickrd.com">{t('contactnow')}</a>
                        </div>
                        <div className="col-md-6 order-md-1">
                            <img src={img3} />
                        </div>
                    </div>
                </div>
         
            </div>
        </>
    );
}