import Header from "../components/Header";
import appStore from "../images/app-store.png"
import googlePlay from "../images/google-play.png"
import background from "../images/clickclick_iphone.png"
import React, { Component } from 'react';

export default function StorePage() {
    return (
        <>
            <div className=" position-relative overflow-hidden">
                <div className="container" style={{ backgroundColor: "#E94E1B"}}>
                    <div className="row position-relative justify-content-center">
                        <div className="col-xl-6 col-lg-6 col-sm-9" data-aos="fade-right" data-aos-duration="800" data-aos-once="true">
                            <div className="banner-image-l-13">
                                <img src={background} alt="" className="w-100" />
                            </div>
                        </div>
                        <div style={{marginTop: "10%"}} className="offset-xl-1 col-xl-5 col-lg-6 col-md-8 col-sm-9" data-aos="fade-left" data-aos-duration="800" data-aos-once="true">
                            <div className="content">
                                <h1>¡Descarga la App!</h1>
                                <p>Disponible para iOS y Android</p>
                                <div className="apps-btn">
                                    <a href="https://apps.apple.com/es/app/click-click-rd/id1626472011" className="app-store"><img src={appStore} alt="" /></a>
                                    <a href="https://play.google.com/apps/testing/com.GSMData.ClickClickApp" className="app-store"><img src={googlePlay} alt="" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-shape-13"></div>
            </div>
        </>
    );
}