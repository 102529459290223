import '../components/Header'
import Header from '../components/Header';
import '../styles/css/main.css'
import { createContext, useEffect, useState } from 'react';
import axios, { isCancel, AxiosError } from 'axios';
import PropertyModel from '../data/models/property';
import PropertyCard from '../components/PropertyCard';
import Footer from '../components/Footer';
import { Checkbox, LinearProgress, ToggleButtonGroup, ToggleButton, TextField, Pagination } from '@mui/material';
import FeaturesChecks from '../components/FeaturesChecks';
import StatesChecks from '../components/StatesChecks';
import PublicationDateSelector from '../components/PublicationDateSelector';
import FilterModel from '../data/models/filters';
import React from 'react';
import i18next from '../services/i18n'
import { useTranslation } from "react-i18next";
import Config from '../config';
import { Place } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { GoogleMap, Marker, withGoogleMap, Map } from 'react-google-maps';
import { MapContainer } from '../components/Map';
import { useMemo } from 'react';
import MapNew from '../components/MapNew';

import FilterListIcon from '@mui/icons-material/FilterList';
import Accordion from '@mui/material/AccordionSummary';

import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



export default function Properties() {

  const { t } = useTranslation();
  const params = useParams("place");
  const [properties, setProperties] = useState([]);
  const [totalProperties, setTotalProperties] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [propertyTypes, setPropertyTypes] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentIndex, setCurrentIndex] = useState(1);

  const [features, setFeatures] = useState([]);
  const [states, setStates] = useState([]);
  const [sort, setSort] = useState("0");
  const [filters, setFilters] = useState(new FilterModel());
  const [markers, setmarkers] = useState([]);

  const [view, setView] = useState("0");

  const [showFilters, setShowFilter] = useState(true);
  const sort_options = ['Recientes', 'Antiguos', 'Baratos', 'Precio más alto', 'Grandes', 'Pequeños'];

  const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);

  useEffect(() => {

    const filters = localStorage.getItem("filters");
    var filterModel = new FilterModel();
    filterModel = JSON.parse(filters);
    setFilters(filterModel);
    setSort(filterModel.SortType.toString());
    setItemsPerPage(filterModel.Limit);
    document.querySelector("#map-view").classList.add('hidden');
    const headers = {
      "content-type": "application/json",
    };

  
    axios.post(Config.apiUrl + 'properties/search', filters, { headers })
      .then(response => {
       
        if(response.data == null){
           result = [];
        }

        var result = response.data.data.properties;
        var total = 0;
        if(result.length > 0){
          total = response.data.data.properties[0].total;
        }
        setTotalProperties(total);
        setPageCount(Math.ceil(total / itemsPerPage));
        setProperties(result);
        var markers = result.map(p => ({ lat: p.latitude, lng: p.longitude, id: p.id }));
        setmarkers(markers);
        setIsLoading(false);

      });

    fetch(Config.apiUrl + "properties/filters?languageCode=" + i18next.language)
      .then(response => response.json())
      .then(data => {
        setFeatures(data.data.features);
        setStates(data.data.stateTypes);
        setPropertyTypes(data.data.propertyTypes);
      });

  }, [])



  function onChangePropertyType(id, checked) {
    if (checked) {
      var index = filters.propertyTypes.length;
      filters.propertyTypes[index] = id;
    }
    else {
      var index = filters.propertyTypes.indexOf(id);
      if (index >= 0) {
        filters.propertyTypes.splice(index, 1);
      }
    }
    applyFilters();
  }

  function onChangeRooms(value, checked) {
    if (checked) {
      filters.Bedrooms = value;
    }
    else {
      filters.Bedrooms = null;
    }
    applyFilters();
  }

  function onChangeBaths(value, checked) {
    //si no hay ninguna marcada se marca >= todas
    if (checked) {
      filters.Baths = value;
    }
    else {
      filters.Baths = null;
    }
    applyFilters();
  }

  function sortProperties(value) {
    setSort(value);
    filters.SortType = Number(value);
    var jsonFilters = JSON.stringify(filters);
    localStorage.setItem("filters", jsonFilters);
    applyFilters();
  }

  function applyFilters(fromIndex = 0, page = 1) {
  
    filters.From = fromIndex;
    setCurrentIndex(page);
    setIsLoading(true);
    const headers = {
      "content-type": "application/json",
    };

    axios.post(Config.apiUrl + 'properties/search', filters, { headers })
      .then(response => {
        var propes = response.data.data.properties;
        var result = propes.map(p => new PropertyModel(p.id, p.name, p.price, p.rooms, p.m2, p.description, p.isFavourited, p.features, p.images));
        var total = 0;
        if(propes.length > 0){
          total = response.data.data.properties[0].total;
        }
        setTotalProperties(total);
        setPageCount(Math.ceil(total/ itemsPerPage));
        setProperties(result);
        setIsLoading(false);
        var jsonFilters = JSON.stringify(filters);
        localStorage.setItem("filters", jsonFilters);
      });
  }

  function onChangeMinPrice(value) {
    if (value === "")
      filters.MinPrice = null;
    else
      filters.MinPrice = value;
    applyFilters()
  }

  function onChangeMaxPrice(value) {
    if (value === "")
      filters.MaxPrice = null;
    else
      filters.MaxPrice = value;
    applyFilters()
  }

  function onChangeMinSize(value) {
    if (value === "")
      filters.MinSize = null;
    else
      filters.MinSize = value;
    applyFilters()
  }

  function onChangeMaxSize(value) {
    if (value === "")
      filters.MaxSize = null;
    else
      filters.MaxSize = value;
    applyFilters()
  }

  function onChangeOperationType(value) {
    filters.operationType = value;
    applyFilters()
  }

  function changeView(value) {
    setView(value);

    if (value == "0") {
      document.querySelector("#listing-view").classList.remove('hidden');
      document.querySelector("#map-view").classList.add('hidden');
    }

    if (value == "1") {
      document.querySelector("#listing-view").classList.add('hidden');
      document.querySelector("#map-view").classList.remove('hidden');
    }
  }

  const pageChanged = (event, value) => {
    window.scrollTo(0, 0)
    var fromIndex =  (value - 1)*itemsPerPage;
    setCurrentIndex(value);
    applyFilters(fromIndex, value);
  };


  function showHideFilters() {
    setShowFilter(!showFilters);

    var filters = document.getElementsByClassName('filters');

    if (showFilters) {
      filters[0].style.display = 'block'
    }
    else {
      filters[0].style.display = 'none'
    }
  }

  return (
    < >
      <div className='first-container' style={{ marginTop: 20 }}>
        <div className='row'>
          <div className='col-6'>
            <h3><strong>{params.place}: {t('showing')} {properties.length} {t('of')} {totalProperties} {t('posts')}</strong></h3>
          </div>
          <div className='col-6'>
            <div className='put-right'>

              <ToggleButtonGroup onChange={e => changeView(e.target.value)} className='btns-order' value={view} exclusive>
                <ToggleButton value="0">{t('list')}</ToggleButton>
                <ToggleButton value="1">{t('map')}</ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
        </div>

      </div>
      <div className='row'>
        <div className='col ' style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
          <div style={{ margin: 5 }}>
            <a onClick={showHideFilters} className='btn-filters'> <FilterListIcon></FilterListIcon> {t('filters')}</a>
          </div>


        </div>

      </div>


      <div className='put-right container'>


        <span style={{ fontSize: 16, paddingRight: 10 }}>{t('sort')}:</span>

        <ToggleButtonGroup className='btns-order'
          value={sort}
          exclusive
          onChange={e => sortProperties(e.target.value)}
        >
          <ToggleButton value="0">{t('recents')}</ToggleButton>
          <ToggleButton value="1">{t('leastrecent')}</ToggleButton>
          <ToggleButton value="2">{t('lowestprice')}</ToggleButton>
          {/* <ToggleButton value="3">{t('highestprice')}</ToggleButton>
            <ToggleButton value="4">{t('biggest')}</ToggleButton>
            <ToggleButton value="5">{t('smallest')}</ToggleButton> */}

        </ToggleButtonGroup>

      </div>

      <div className='row'>

        <div className='col-md-3 col-sm-12'>

          <div className='filters'>

            <label htmlFor="propertyType" className="form-label">{t('propertyop')}:</label>
            <select id="propertyType" className="form-select form-select mb-3" onChange={e => onChangeOperationType(e.target.value)}>
              <option defaultValue={filters.operationType == "AB94A3AB-1F99-43CC-800A-6CF9947981B2"} value="AB94A3AB-1F99-43CC-800A-6CF9947981B2">{t('buy')}</option>
              <option defaultValue={filters.operationType == "2848B78F-0341-4CBB-AD95-0C5408629901"} value="2848B78F-0341-4CBB-AD95-0C5408629901">{t('rent')}</option>
            </select>
            <div id="passwordHelpBlock" className="form-text">

            </div>

            <div className="input-group mb-3">
              <label htmlFor="price" className="form-label">{t('price')}:</label>
              <div className='row'>
                <div className='col-6'>
                  <TextField
                    label="Min"
                    InputProps={{ inputProps: { min: 100 } }}
                    type='number'
                    min={1}
                    id="outlined-size-small"
                    size="small"
                    value={filters.MinPrice}
                    onChange={e => onChangeMinPrice(e.target.value)}
                  />
                </div>

                <div className='col-6'>
                  <TextField
                    label="Max"
                    InputProps={{ inputProps: { min: 100 } }}
                    type='number'
                    id="outlined-size-small"
                    defaultValue={filters.MaxPrice}
                    value={filters.MaxPrice}

                    size="small"
                    onChange={e => onChangeMaxPrice(e.target.value)}
                  />
                </div>

              </div>
            </div>

            <div className="input-group mb-3">
              <label htmlFor="price" className="form-label">{t('size')}:</label>
              <div className='row'>
                <div className='col-6'>
                  <TextField
                    InputProps={{ inputProps: { min: 1 } }}
                    label="Min"
                    type='number'
                    id="outlined-size-small"
                    defaultValue={filters.MinSize}
                    value={filters.MinSize}
                    size="small"
                    onChange={e => onChangeMinSize(e.target.value)}
                  />
                </div>

                <div className='col-6'>
                  <TextField
                    InputProps={{ inputProps: { min: 1 } }}
                    label="Max"
                    type='number'
                    id="outlined-size-small"
                    defaultValue={filters.MaxSize}
                    value={filters.MaxSize}
                    size="small"
                    onChange={e => onChangeMaxSize(e.target.value)}
                  />
                </div>
              </div>
            </div>


            <FeaturesChecks features={features} setCurrentIndex={setCurrentIndex} setTotalProperties={setTotalProperties} filters={filters} setFilters={setFilters} setPageCount={setPageCount} itemsPerPage={itemsPerPage} setProperties={setProperties} setIsLoading={setIsLoading}></FeaturesChecks>

            <StatesChecks states={states} setCurrentIndex={setCurrentIndex} filters={filters} setFilters={setFilters} setTotalProperties={setTotalProperties} setPageCount={setPageCount} itemsPerPage={itemsPerPage} setProperties={setProperties} setIsLoading={setIsLoading}></StatesChecks>

          <PublicationDateSelector filters={filters} setFilters={setFilters} setCurrentIndex={setCurrentIndex} setTotalProperties={setTotalProperties} setPageCount={setPageCount} itemsPerPage={itemsPerPage} setProperties={setProperties} setIsLoading={setIsLoading}></PublicationDateSelector>
            <div className="input-group mb-3">
              <label htmlFor="propertyTypes" className="form-label">{t('propertytype')}:</label>

              <div className='row'>

                {propertyTypes.map(f => (
                  <div key={f.id} className="form-check" style={{ paddingLeft: 0 }} >
                    <Checkbox id={f.id} onChange={e => onChangePropertyType(e.target.id, e.target.checked)}
                      checked={filters.propertyTypes.includes(f.id)}
                      sx={{
                        color: "#E94E1B",
                        '&.Mui-checked': {
                          color: "#E94E1B",
                        },
                      }}
                    />
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                      {f.nombre}
                    </label>
                  </div>
                ))}

              </div>

            </div>

            <div className="input-group mb-3">
              <label>{t('rooms')}:</label>

              <div className='row'>
                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <Checkbox onChange={e => onChangeRooms(1, e.target.checked)} checked={filters.Bedrooms === 1}
                    sx={{
                      color: "#E94E1B",
                      '&.Mui-checked': {
                        color: "#E94E1B",
                      },
                    }}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    1
                  </label>
                </div>
                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <Checkbox onChange={e => onChangeRooms(2, e.target.checked)} checked={filters.Bedrooms === 2}
                    sx={{
                      color: "#E94E1B",
                      '&.Mui-checked': {
                        color: "#E94E1B",
                      },
                    }}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    2
                  </label>
                </div>
                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <Checkbox onChange={e => onChangeRooms(3, e.target.checked)} checked={filters.Bedrooms === 3}
                    sx={{
                      color: "#E94E1B",
                      '&.Mui-checked': {
                        color: "#E94E1B",
                      },
                    }}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    3
                  </label>
                </div>
                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <Checkbox onChange={e => onChangeRooms(4, e.target.checked)} checked={filters.Bedrooms >= 4}
                    sx={{
                      color: "#E94E1B",
                      '&.Mui-checked': {
                        color: "#E94E1B",
                      },
                    }}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    4 habitaciones o más
                  </label>
                </div>
              </div>
            </div>

            <div className="input-group">
              <label>{t('baths')}:</label>

              <div className='row'>
                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <Checkbox onChange={e => onChangeBaths(1, e.target.checked)} checked={filters.Baths === 1}
                    sx={{
                      color: "#E94E1B",
                      '&.Mui-checked': {
                        color: "#E94E1B",
                      },
                    }}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    1
                  </label>
                </div>
                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <Checkbox onChange={e => onChangeBaths(2, e.target.checked)} checked={filters.Baths === 2}
                    sx={{
                      color: "#E94E1B",
                      '&.Mui-checked': {
                        color: "#E94E1B",
                      },
                    }}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    2
                  </label>
                </div>


                <div className="form-check" style={{ paddingLeft: 0 }}>
                  <Checkbox onChange={e => onChangeBaths(3, e.target.checked)} checked={filters.Baths >= 3}
                    sx={{
                      color: "#E94E1B",
                      '&.Mui-checked': {
                        color: "#E94E1B",
                      },
                    }}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    3 baños o más
                  </label>
                </div>
              </div>

            </div>
          </div>

        </div>
        <div className='col-md-9 col-sm-12 mb-3'>

          <div id='listing-view'>
            {isLoading ? <LinearProgress /> :
              properties.map(o => (
                <PropertyCard property={o} key={o.id}></PropertyCard>
              ))
            }
            <Pagination page={currentIndex} count={pageCount} onChange={pageChanged} />
          </div>
          <div id='map-view' >
            <MapNew markers={markers}></MapNew>
          </div>
        </div>
      </div>
    </>
  );
}